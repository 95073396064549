import cart from '../../cart/models/Cart';
import { RESTAURANTS_STORE } from '../../restaurants/stores/restaurants.store';
import { VueRecaptcha } from 'vue-recaptcha';

declare var CONSTANTS: any;

declare var window: any;

export default {
  components: {
    VueRecaptcha
  },
  computed: {
    recaptchaSiteKey() {
      // Use Google's debug test key if running through Cypress tests
      // https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
      return window.Cypress ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : CONSTANTS.RECAPTCHA_SITE_KEY;
    },

    // getters
    cartId() {
      return cart.cart.objectId;
    },

    restaurantId() {
      return this.$store.state[RESTAURANTS_STORE].restaurant.objectId;
    }
  },

  data() {
    return {
      callback: null,
      performingRecaptcha: false,
      recaptchaRendered: false,
      recaptchaToken: null
    };
  },

  methods: {
    performRecaptcha(callback) {
      this.resetRecaptcha();
      this.callback = callback;
      this.performingRecaptcha = true;
      this.$refs.invisibleRecaptcha.execute();
    },

    onRecaptchaVerify(token) {
      this.recaptchaToken = token;

      this.performingRecaptcha = false;

      if (typeof this.callback === 'function') {
        this.callback(token);
      } else {
        this.onRecaptchaSuccess();
      }
    },

    // Override this in component to do something with this after response
    onRecaptchaExpired() {
      console.log('recaptcha expired');
      this.performingRecaptcha = false;
    },

    onRecaptchaRendered() {
      this.recaptchaRendered = true;
    },

    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset(); // Direct call reset method
      this.performingRecaptcha = false;
    }
  }
};
