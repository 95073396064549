import {createSetterMutations, setStateThroughMutation} from '../../common/data/store.helpers';
import {IMenuItem, IRequiredItem, ITopping} from '../../menu/models/Item';
import cartService from '../services/cart.service';
import notifierService from '../../common/services/notifier.service';
import CartOptimization from '../services/cart-optimization';
import {IZoneArea} from '../../restaurants/types/restaurant.types';
import {CartRecord} from '../cart.types';

export const CART_STORE = 'cart';

export const initialState = {
  cart: undefined, // ICart
  cartError: undefined, // string
  giftCardBalance: 0,
  loading: false,
  readyTime: null,
  selectedItemObjectId: null, // string
  updating: false,
  editingCheckoutItem: false,
  maxToppingsData: {},
  shouldCheckForSuggestions: false
};

let itemMapResolve;
let itemMapPromise = new Promise(resolve => itemMapResolve = resolve);
let lastCartUpdate;

const dispatchCartUpdate = <T>(dispatch, state, callback: () => Promise<T>, force: boolean = false) => {
  lastCartUpdate = callback;

  const update = () => {
    return callback().then(response => {
      return dispatch('updateCart', { response, isLatestCartUpdate: force || lastCartUpdate === callback });
    });
  };

  if (state.loading) {
    return update();
  } else {
    return dispatch('setLoading', true).then(update);
  }
};

export default {
  name: CART_STORE,
  namespaced: true,
  state: initialState,
  mutations: {
    ...createSetterMutations(initialState),
  },
  getters: {
    selectedItem(state) {
      return state.cart.items.find(item => item.objectId === state.selectedItemObjectId);
    },
    outOfStock(state) {
      return state.cart.outOfStock;
    },
    selectedItemMaxToppingsData(state) {
      return state.maxToppingsData[state.selectedItemObjectId];
    }
  },
  actions: {
    addCoupon({ commit, dispatch, state }, payload: { cartId: string, couponId: string, promoId?: string }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.addCoupon(payload).then(result => {
          if (!result.success && result.error) {
            notifierService.error(result.error);

            throw false;
          } else {
            return result;
          }
        });
      });
    },
    addCouponCode({ commit, dispatch, state }, payload: { cartId: string, couponCode: string }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.addCouponCode(payload).then(response => {
          if (!response.success && response.error) {
            notifierService.error('The Coupon code is invalid. If you are a loyalty customer, ' +
              'please be sure to log into your account to retrieve your loyalty coupons.');

            throw false;
          } else {
            return response;
          }
        });
      });
    },
    addCommentsToItem({ commit, dispatch, state }, payload: { cartId: string, itemId: string, comments: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.addCommentsToItem(payload));
    },
    addItem({ commit, dispatch, state }, payload: { cartId: string, itemId: string, sizeId: string, showSuggestion: boolean }) {
      return dispatchCartUpdate(dispatch, state, () => {
        let func;

        if (payload.sizeId) {
          func = cartService.addItemWithSize;
        } else {
          func = cartService.addItem;
        }

        return func.call(cartService, payload).then(response => {
          if (!response || !response.items) {
            let result;

            if (response.error) {
              result = response.error.message;
            }

            if (!result) {
              result = false;
            }

            throw result;
          }

          const selectedItem: IMenuItem = response.items.find((cartItem: IMenuItem) => {
            return cartItem.objectId === response.selectedItem;
          });

          return dispatch('selectItem', selectedItem).then(() => {
            return Object.assign({}, response);
          });
        });
      });
    },
    addNoRequiredItem({ commit, dispatch, state }, payload: { cartId: string, requirement: string, parent: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.addNoRequiredItem(payload));
    },
    addRequiredItem({ commit, dispatch, state }, payload: { cartId: string, itemId: string, parentId: string, requirementId: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.addRequiredItem(payload));
    },
    addUser({ commit }, payload: { cartId: string, userId: string }) {
      return cartService.addUserToCart(payload);
    },
    checkOrderTime({ dispatch, state }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.checkOrderTime());
    },
    checkStatus({ commit, state }) {
      if (!state.cart || !state.cart.objectId) {
        return Promise.reject();
      }

      return cartService.checkCartStatus(state.cart.objectId).then(response => {
        if (response && response.error && response.error.message && response.error.message.toUpperCase() === 'SUBMISSION FAILURE') {
          setStateThroughMutation(commit, 'updating', false);
          setStateThroughMutation(commit, 'cartError', 'Submission Failure');
        }

        setStateThroughMutation(commit, 'updating', false);

        return response;
      });
    },
    clear({ dispatch, state }) {
      dispatch('selectItem', null);
      dispatch('resetMaxToppingsData');

      return dispatchCartUpdate(dispatch, state, () => cartService.clearCart(), true);
    },

    fetchBySession({ dispatch, state }, cartId) {
      const fetch = () => dispatchCartUpdate(dispatch, state, () => cartService.fetchCartBySession());

      if (cartId && state.cart) {
        return cartService.getCartUpdateTime(cartId).then(updateTimestamp => {
          if (updateTimestamp > state.cart.updateTimeStamp) {
            return fetch();
          } else {
            return state.cart;
          }
        });
      } else {
        return fetch();
      }
    },

    fetchGiftCardBalance({ commit, dispatch }, payload: {
      cartId: string,
      giftCardCode: string,
      giftCardCvv: string,
      locationId: string,
      recaptchaToken: string }) {
      setStateThroughMutation(commit, 'giftCardBalance', 0);

      return cartService.fetchGiftCardBalance(payload).then(response => {
        setStateThroughMutation(commit, 'giftCardBalance', response.balance);

        return response;
      });
    },
    reorder({ commit, dispatch, state }, cartId: string) {
      return dispatchCartUpdate(dispatch, state, () => cartService.reorderCart(cartId));
    },
    removeAllRequiredItemInstances(
      { commit, dispatch, state },
      payload: { cartId: string, parentInstanceId: string, requiredItem: IRequiredItem }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.removeAllInstancesOfRequiredItem(payload).then(response => {
          if (response.error) {
            throw false;
          } else {
            return response;
          }
        });
      });
    },
    removeDeliveryZone({ commit, dispatch, state }, cartId: string ) {
      return dispatchCartUpdate(dispatch, state, () => cartService.removeDeliveryZone(cartId));
    },
    removeItem({ commit, dispatch, state, getters }, payload: { cartId: string, itemId: string }) {
      if (getters.selectedItem && payload.itemId === getters.selectedItem.itemId) {
        dispatch('selectItem', null);
      }

      return dispatchCartUpdate(dispatch, state, () => cartService.removeItem(payload));
    },
    removeItems({ commit, dispatch, state, getters }, payload: { cartId: string, itemIds: string[] }) {
      if (getters.selectedItem && payload.itemIds.indexOf(getters.selectedItem.itemId) !== -1) {
        dispatch('selectItem', null);
      }

      return dispatchCartUpdate(dispatch, state, () => cartService.removeItems(payload));
    },
    scheduleAsap({ dispatch, state }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.scheduleAsap());
    },
    selectItem({ commit }, item: IMenuItem) {
      return setStateThroughMutation(commit, 'selectedItemObjectId', item ? item.objectId : null);
    },
    setDeliveryZone({ commit, dispatch, state }, payload: { cartId: string, zone: IZoneArea | string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setDeliveryZone(payload));
    },
    setLoading({ commit }, value: boolean) {
      return setStateThroughMutation(commit, 'loading', value);
    },
    setOrderName({ commit, dispatch, state }, payload: { orderName: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setOrderName(payload));
    },
    setOrderTime({ commit, dispatch, state }, payload: { date: string, time: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setOrderTime(payload));
    },
    setOrderType({ commit, dispatch, state }, payload: { curbside: boolean, orderTypeId: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setOrderType(payload));
    },
    setTenderType({ commit, dispatch, state }, payload: { cartId: string, tenderTypeId: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setTenderType(payload));
    },
    setQuantity({ commit, dispatch, state }, payload: { cartId: string, itemId: string, quantity: number }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.setQuantity(payload));
    },
    splitItem({ commit, dispatch, state }, payload: { cartId: string, itemObjectId: string, itemId: string }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.splitItem(payload).then(response => {
          if (response.error) {
            throw false;
          } else {
            return response;
          }
        });
      });
    },
    unsplitItem({ commit, dispatch, state }, payload: { cartId: string, itemObjectId: string }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.unsplitItem(payload).then(response => {
          if (response.error) {
            throw false;
          } else {
            return response;
          }
        });
      });
    },
    updateCart({ commit, dispatch, state, rootState }, { response, isLatestCartUpdate }) {
      return itemMapPromise.then(() => {
        if (isLatestCartUpdate) {
          CartOptimization.handleResponse(response.items, rootState.menu.itemMap);
        }

        return dispatch('setLoading', false).then(() => {
          let cart;

          if (response) {
            if (response.cart && !response.success && response.error) {
              setStateThroughMutation(commit, 'cartError', response.error.message);
            } else if (response.items) {
              if (isLatestCartUpdate) {
                cart = new CartRecord(response);
                setStateThroughMutation(commit, 'cart', cart);
              } else {
                cart = state.cart;
              }

              setStateThroughMutation(commit, 'cartError', undefined);
            }
          }

          return cart;
        });
      });
    },
    updateRequirements({ commit, dispatch, state },
                       payload: { cartId: string, itemInstanceId: string, requirements: IRequiredItem[] }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.changeRequirements(payload).then(response => {
          if (response.error) {
            throw false;
          } else {
            return response;
          }
        });
      });
    },
    updateSize({ commit, dispatch, state }, payload: { cartId: string, itemId: string, sizeId: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.addSizeToItem(payload));
    },
    updateStyle({ commit, dispatch, state }, payload: { cartId: string, itemId: string, styleId: string }) {
      return dispatchCartUpdate(dispatch, state, () => cartService.addStyleToItem(payload));
    },
    updateToppings({ commit, dispatch, state }, payload: { cartId: string, itemId: string, toppings: ITopping[] }) {
      return dispatchCartUpdate(dispatch, state, () => {
        return cartService.changeToppings(payload).then(response => {
          if (!response.objectId) {
            if (!response.success) {
              notifierService.warning('There was an error modifying your item.  Please try again.');

              return this.cart;
            } else {
              throw false;
            }
          } else {
            return response;
          }
        });
      });
    },
    setMaxToppingsData(
      { commit, state },
      data: Record<string,
      { inclusionsCount: number, allowedToppings: number }>
    ) {
      return setStateThroughMutation(commit, 'maxToppingsData', {
        ...state.maxToppingsData,
        ...data
      });
    },
    resetMaxToppingsData({ commit }) {
      return setStateThroughMutation(commit, 'maxToppingsData', {});
    }
  }
};

export function setUp(store) {
  store.watch(state => state.menu.itemMap, () => {
    if (store.state.menu.itemMap) {
      itemMapResolve();
    }
  });
}
