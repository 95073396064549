import HttpService, { IRequestArgs, Response } from './axios-http.service';
import {
  API_SUBMIT_LOGIN_REQUEST_URL,
  API_SUBMIT_REGISTER_REQUEST_URL
} from "../../profile/profile.constants";
import profile from "../../profile/models/Profile";
import analyticsManager from '../../common/services/analytics-manager.service';

export class BaseApiService {
  http: HttpService;

  constructor() {
    this.http = new HttpService();
  }

  request(options: IRequestArgs): any {
    if (options.body) {
      if (typeof options.body !== 'string') {
        options.body = JSON.stringify(options.body);
      }

      options.headers = Object.assign({
        'Content-Type': 'application/json'
      }, options.headers ? options.headers : {});
    }

    return this.http.request(options).then((res: Response) => {
      let response;
      let contentType = res.headers['content-type'];

      if (!contentType) {
        contentType = res.headers['Content-Type'];
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        response = res.data;

        if (typeof response === 'string') {
          response = JSON.parse(response);
        }

        if (response.success) {
          if (response.type) {
            if (res.config.url === API_SUBMIT_LOGIN_REQUEST_URL
              || res.config.url === API_SUBMIT_REGISTER_REQUEST_URL) {
              localStorage.setItem('auth_token', response.token);
            }

            return response[response.type];
          } else {
            return response;
          }
        }

        if (response.error) {
          analyticsManager.track('API Error', response.error);
        }
      } else {
        response = res;
      }

      return response;
    }).catch((ex) => {
      if (ex.response && ex.response.status === 403) {
        profile.expireSession();
      }
    });
  }
}
